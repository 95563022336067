import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Section from '@containers/Section';
import CompaniesPreview from '@containers/CompaniesPreview';
import { Description, HeroContent, SectionTitle } from './hero.styled';

const Hero = () => {
  const data = useStaticQuery(graphql`
    query Heroquery {
      heroJson {
        title
        primaryDescription
        secondaryDescription
      }
    }
  `);

  const heroData = data.heroJson;
  const { title, primaryDescription, secondaryDescription } = heroData;
  return (
    <div className="bg-white">
      <div className="mx-auto px-4 max-w-screen-xl sm:px-6 lg:px-8">
        <SectionTitle>{title}</SectionTitle>
        <main className="lg:relative">
          <div className="mx-auto max-w-7xl w-full">
            <div className="pr-4 lg:w-2/3 sm:pr-8 xl:pr-16">
              <HeroContent>
                <Description
                  dangerouslySetInnerHTML={{ __html: primaryDescription }}
                />
                <Description>{secondaryDescription}</Description>
                <Link
                  to="/about"
                  className="text-xl font-xl text-darkBlue my-4"
                >
                  Learn more about me &rarr;
                </Link>
              </HeroContent>
            </div>
          </div>
          <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/3 lg:h-full">
            <img
              className="absolute inset-0 w-full h-full object-cover rounded"
              src="https://res.cloudinary.com/steven-sajja/image/upload/v1600457992/Sajja%20Images/Sajja_v4kful.jpg"
              alt="Steven Sajja"
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Hero;
