import styled from 'styled-components';

const ProjectContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
`;
const ProjectTitle = styled.h1`
  color: ${({ theme }) => theme.colors.themeColor};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSizes.subHeading};
  line-height: ${({ theme }) => theme.lineHeight.medium};
`;
const ProjectDate = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  margin: 8px 0;
`;
const ProjectDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.body};
  color: ${({ theme }) => theme.colors.themeColor};
  line-height: ${({ theme }) => theme.lineHeight.regular};
  margin: 8px 0;
`;

const ProjectLink = styled.a`
  width: fit-content;
  font-size: ${({ theme }) => theme.fontSizes.body};
  color: ${({ theme }) => theme.colors.darkBlue};
  line-height: ${({ theme }) => theme.lineHeight.regular};

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
  }
`;

export {
  ProjectContainer,
  ProjectTitle,
  ProjectDate,
  ProjectDescription,
  ProjectLink,
};
