import React from 'react';
import KeyValuePropositions from '@src/hooks/use-keyValueProps';
import { CTA, KeyValuePropsContainer } from './keyValueProps.styled';

const KeyValuePropsPreview = () => {
  const propositions = KeyValuePropositions();
  return (
    <KeyValuePropsContainer>
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-12">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl leading-9 font-extrabold text-darkBlue tracking-tight sm:text-4xl">
              What makes me different?
            </h2>
            <p className="text-xl leading-7 text-gray-300">
              As a T-Shaped individual, at the intersection of business and
              technology, I build and sell software products.
            </p>
          </div>
          <div className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
            {propositions.map((proposition, key) => {
              const { title, subHeading, description, cta, icon } = proposition;
              return (
                <div className="space-y-4" key={key}>
                  <div className="h-8 w-8 text-darkBlue-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d={icon}
                      />
                    </svg>
                  </div>
                  <div className="leading-6 font-medium space-y-1">
                    <h4 className="text-2xl text-darkBlue">{title}</h4>
                    <p className="text-teal">{subHeading}</p>
                  </div>
                  <div className="text-lg leading-7">
                    <p className="text-gray-500">{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <CTA
            href="https://res.cloudinary.com/steven-sajja/image/upload/v1602834425/SS%20Site/Profile_wnq5ry.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Look at my CV to learn about my professional background ▹
          </CTA>
        </div>
      </div>
    </KeyValuePropsContainer>
  );
};
export default KeyValuePropsPreview;
