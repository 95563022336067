import { graphql, useStaticQuery } from 'gatsby';

export const useOfferings = () => {
  const data = useStaticQuery(graphql`
    query OfferingsQuery {
      offeringsJson {
        offerings {
          title
          description
          availability
        }
      }
    }
  `);

  const { offerings } = data.offeringsJson;

  return offerings;
};

export default useOfferings;
