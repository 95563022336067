import React from 'react';
import { theme } from '@src/theme/theme';
import { SUBSTACK_IFRAME } from '@constants';
import IFrame from '@src/components/iframe';
import { Newsletter } from './substack.styled';

const Substack = () => (
  <Newsletter>
    <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-12">
      <div className="space-y-6">
        <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-3xl leading-9 font-extrabold text-white tracking-tight sm:text-4xl">
            Newsletter
          </h2>
          {/* <p className="text-xl leading-7 text-gray-300">
            I plan to publish articles and think pieces infrequently sharing my
            take on business, technology, startups etc. <br /> Subscribe below
            to stay updated!
          </p> */}
        </div>
        <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start">
          <p className="flex-grow sm:pr-16 text-xl font-medium title-font text-gray-300">
            I plan to publish articles and think pieces infrequently focusing on
            business, technology, startups etc. Subscribe to stay updated!
          </p>
          <IFrame iframe={SUBSTACK_IFRAME} />
        </div>
      </div>
    </div>
  </Newsletter>
);

export default Substack;
