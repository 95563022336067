import styled from 'styled-components';

const BadgeContainer = styled.span.attrs((props) => ({
  className: `inline-flex items-center align-middle px-2.5 py-0.5 rounded-full text-sm font-medium leading-4 ml-4`,
}))`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  padding: 2.5px 1px;
  span {
    margin: 0 2px;
  }
`;

export { BadgeContainer };
