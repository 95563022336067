import styled from 'styled-components';
import { PATTERN } from '@constants';

const KeyValuePropsContainer = styled.div.attrs((props) => ({
  className: 'bg-white',
}))`
  ${PATTERN}
`;

const CTA = styled.a.attrs((props) => ({
  className:
    'text-lg leading-5 text-darkBlue-700 font-medium hover:text-darkBlue',
}))`
  :hover {
    box-shadow: none;
  }
`;

export { KeyValuePropsContainer, CTA };
