import React from 'react';
import PropTypes from 'prop-types';
import { BadgeContainer } from './badge.styled';

const Badge = ({ text, backgroundColor, textColor }) => (
  <BadgeContainer backgroundColor={backgroundColor} textColor={textColor}>
    <span>{text}</span>
  </BadgeContainer>
);

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default Badge;
