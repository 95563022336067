import { graphql, useStaticQuery } from 'gatsby';

export const useCompanies = () => {
  const data = useStaticQuery(graphql`
    query CompaniesQuery {
      companiesJson {
        companies {
          name
          logo
          link
        }
      }
    }
  `);

  const { companies } = data.companiesJson;

  return companies;
};

export default useCompanies;
