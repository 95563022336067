import { graphql, useStaticQuery } from 'gatsby';

export const useTestimonials = () => {
  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      testimonialsJson {
        testimonials {
          name
          image
          words
          role
        }
      }
    }
  `);

  const { testimonials } = data.testimonialsJson;

  return testimonials;
};

export default useTestimonials;
