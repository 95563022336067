import styled from 'styled-components';

const Logo = styled.img.attrs((props) => ({
  className: props.className ? props.className : 'h-12',
}))`
  filter: grayscale(100%);

  &:hover {
    filter: unset;
  }
`;

export { Logo };
