import styled from 'styled-components';
import { PATTERN } from '@constants';

const OfferingContainer = styled.div.attrs((props) => ({
  className: 'bg-darkBlue-100',
}))`
  ${PATTERN}
`;

export { OfferingContainer };
