import styled from 'styled-components';
import { PATTERN } from '@constants';

const IndividualTestimonial = styled.p.attrs((props) => ({
  className: 'leading-relaxed leading-7 text-lg mb-6',
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const TestimonialContainer = styled.div.attrs((props) => ({
  className: 'bg-darkBlue-900 text-white',
}))`
  background-color: #1a3e50;
  // background-blend-mode: hard-light;
  // ${PATTERN}
`;

export { IndividualTestimonial, TestimonialContainer };
