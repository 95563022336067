import { graphql, useStaticQuery } from 'gatsby';

export const useKeyValuePropositions = () => {
  const data = useStaticQuery(graphql`
    query KeyValuePropositionsQuery {
      keyValuePropositionsJson {
        keyValuePropositions {
          title
          subHeading
          description
          cta
          icon
        }
      }
    }
  `);

  const { keyValuePropositions } = data.keyValuePropositionsJson;

  return keyValuePropositions;
};

export default useKeyValuePropositions;
