import React from 'react';
import Companies from '@hooks/use-companies';
import { Logo } from './companiesPreview.styled';

const CompaniesPreview = () => {
  const companies = Companies();
  return (
    <div className="bg-white">
      <div className="sm: mt-12 mx-auto mb-12 px-4 max-w-screen-xl sm:px-6 lg:px-8">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <p className="mb-6 text-center lg:text-left text-base leading-6 font-semibold uppercase text-darkBlue tracking-wider">
              {/* Trusted by over 5 very average small businesses */}
              Companies I have worked with
            </p>
            <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-6">
              {companies.map((co, key) => (
                <div
                  key={key}
                  className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
                >
                  <Logo src={co.logo} alt={co.name} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesPreview;
