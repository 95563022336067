import React from 'react';
import PageLayout from '@containers/PageLayout';
import CompaniesPreview from '@containers/CompaniesPreview';
import TestimonialsPreview from '@containers/Testimonials';
import Hero from '@components/hero/Hero';
import PostsPreview from '@components/PostsPreview/PostsPreview';
import ProjectsSection from '@containers/ProjectsSection/ProjectsSection';
import ConvertKit from '@components/ConvertKit';
import Banner from '@components/ui/Banner/';
import Offering from '@src/containers/Offering';
import KeyValueProps from '@src/containers/KeyValueProps';
import Substack from '@src/containers/Substack';


const IndexPage = () => {
  return (
    <PageLayout title="Home">
      <Hero />
      <CompaniesPreview />
      <Offering />
      <TestimonialsPreview />
      <KeyValueProps />
      <PostsPreview />
      {/* <ProjectsSection /> */}
      <Substack />
      {/* <ConvertKit /> */}
      {/* <Banner /> */}
    </PageLayout>
  );
};

export default IndexPage;
