import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@components/ui/Icons';
import { Transition } from '@tailwindui/react';
import { Link } from 'gatsby';

const Banner = (props) => {
  const [isVisible, setIsDismissed] = useState(true);
  const { bannerMessage } = props;
  return (
    <Transition
      show={isVisible}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
        <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="p-2 rounded-lg bg-darkBlue shadow-lg sm:p-3">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-teal">
                  <Icon name="speaker" className="text-white" />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">{bannerMessage}</span>
                  <span className="hidden md:inline">{bannerMessage}</span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <div className="rounded-md shadow-sm">
                  <Link
                    to="/products"
                    className="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-darkBlue bg-white hover:text-darkBlue hover:shadow-none hover:text-opacity-75 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-teal hover:shadow-none focus:outline-none focus:bg-teal transition ease-in-out duration-150"
                  aria-label="Dismiss"
                  onClick={() => setIsDismissed(!isVisible)}
                >
                  <Icon name="close" className="text-white" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

Banner.defaultProps = {
  bannerMessage: `Big news! We're excited to announce a brand new product.`,
};
Banner.propTypes = {
  bannerMessage: PropTypes.string,
};

export default Banner;
