import styled from 'styled-components';
import { widthFrom, widthUntil } from '@theme/theme';

const HeroContent = styled.div`
  margin-top: 60px;
  margin-bottom: 30px;
  ${widthUntil.TABLET`
      margin-bottom: 30px;
  `}
`;

const Link = styled.a`
  border-bottom: 2px dashed ${({ theme }) => theme.colors.accentYellow};
  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.accentYellow};
  }
`;
const Description = styled.p`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.hero};
  line-height: 30px;
  color: ${({ theme }) => theme.colors.themeColor};
  margin-bottom: 21px;
  ${widthUntil.MOBILE`
      font-size: ${({ theme }) => theme.fontSizes.subHeading};
  `}

  a {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    border-bottom: 1px solid ${({ theme }) => theme.colors.accentYellow};
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.accentYellow};
    &:hover {
      // transition-duration: 150ms;
      // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      border-bottom: 1px solid ${({ theme }) => theme.colors.accentYellow};
      box-shadow: inset 0 -30px 0 0 ${({ theme }) => theme.colors.accentYellow};
    }
  }
`;

const SectionTitle = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSizes.heading};
  line-height: ${({ theme }) => theme.lineHeight.heading};
  margin: ${({ margin }) => margin || 0};
  color: ${({ theme }) => theme.colors.themeColor};
  ${widthUntil.TABLET`
    font-size: ${({ theme }) => theme.fontSizes.mobileHeading};
  `}
`;

export { Description, HeroContent, Link, SectionTitle };
// ALTERNATIVE
// a {
//     font-weight: ${({ theme }) => theme.fontWeight.semiBold};
//     border-bottom: 2px dashed ${({ theme }) => theme.colors.borderDash};
//     &:hover {
//       border-bottom: 2px solid ${({ theme }) => theme.colors.accentYellow};
//       box-shadow: inset 0 -30px 0 0 ${({ theme }) => theme.colors.accentYellow};
//     }
//   }
