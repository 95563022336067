import React from 'react';
import Section from '@containers/Section';
import { theme } from '@src/theme/theme';
import Offerings from '@src/hooks/use-offering';
import Badge from '@src/components/badge';
import { OfferingContainer } from './offering.styled';

const Offering = () => {
  const offerings = Offerings();
  return (
    <OfferingContainer>
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-12">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl leading-9 font-extrabold text-darkBlue tracking-tight sm:text-4xl">
              How can I help?
            </h2>
            <p className="text-xl leading-7 text-gray-300">
              I build technology to transform and bring clarity to your
              business. This is how I can help you.
            </p>
          </div>
          <ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
            {offerings.map((offering, key) => {
              const { title, description, availability } = offering;
              return (
                <li
                  className="py-10 px-6 bg-darkBlue-900 text-center rounded-lg xl:px-10 xl:text-left"
                  key={key}
                  style={{ listStyle: 'none' }}
                >
                  <div className="space-y-6 xl:space-y-10">
                    <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div className="font-medium leading-6 space-y-1">
                        <h4 className="text-white text-2xl mb-4">
                          {title}
                          {availability ? (
                            <Badge
                              text={availability ? 'Available' : ''}
                              backgroundColor="#C6F6D5"
                              textColor="#276749"
                            />
                          ) : null}
                        </h4>
                        <p className="text-darkBlue-200 text-lg leading-relaxed">
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <section className="text-gray-700 body-font">
          <div className="container pt-12 mx-auto">
            <p className="text-lg leading-5 text-gray-300 font-medium">
              Work with me ▹
            </p>
            <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start">
              <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">
                Reach out to me on{' '}
                <a href="mailto:hello@stevensajja.com">hello@stevensajja.com</a>
                . If you have a bigger project, work with my team at{' '}
                <a
                  href="https://www.boragrowth.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bora Growth Partners
                </a>
              </h1>
              {/* <button className="flex-shrink-0 text-white bg-teal border-0 py-2 px-8 focus:outline-none hover:bg-teal-800 rounded text-lg mt-10 sm:mt-0">
                Book time with me
              </button> */}
            </div>
          </div>
        </section>
      </div>
    </OfferingContainer>
  );
};

export default Offering;
